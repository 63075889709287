<template>
  <DefaultLayout>
    <template v-slot:header>
      <FilterHeader
        :items="[$t('tasks.filters.mine'), $t('tasks.filters.all'), $t('tasks.filters.archived'), $t('tasks.filters.created')]"
        :onChange="onFilterChange"
      />
      <v-spacer></v-spacer>
      <AddButton :onClick="openEditor" />
    </template>
    <v-layout>
      <v-flex>
        <TaskManager ref="taskManager" :selectedFilter="selectedFilter" />
      </v-flex>
    </v-layout>
  </DefaultLayout>
</template>

<script>
// @ is an alias to /src
import DefaultLayout from "@/layouts/default.vue";
import TaskManager from "@/components/tasks/taskmanager.vue";
import AddButton from "@/components/_common/addbutton";
import FilterHeader from "@/components/_common/filterheader";

export default {
  name: "taskmanager",
  data() {
    return {
      selectedFilter: -1,
    };
  },
  computed: {},
  components: {
    DefaultLayout,
    TaskManager,
    AddButton,
    FilterHeader,
  },
  created() {},
  watch: {},
  methods: {
    openEditor: function() {
      this.$refs.taskManager.openEditor();
    },
    onFilterChange: function(val) {
      this.selectedFilter = val;
    },
  },
};
</script>
