<template>
  <div>
    <TaskList :tasks="tasks" :onAction="onAction" />
    <v-navigation-drawer v-model="drawer" absolute temporary right app width="75%">
      <TaskCreate :onClose="closeEditor" :onSuccess="loadTasks" ref="taskCreate" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
import { formatMixin, listMixin } from "@/helpers/mixins";

import TaskCreate from "./taskcreate";
import TaskList from "./tasklist";

export default {
  mixins: [formatMixin, listMixin],

  components: {
    TaskCreate,
    TaskList,
  },

  props: {
    selectedFilter: {
      type: Number,
      default: -1,
    },
  },

  data() {
    return {
      tasks: [],
      drawer: false,
      filter: ["assignedtome=true", "", "state=archived", "imowner=true"],
    };
  },

  mounted() {
    this.loadTasks();
  },

  watch: {
    selectedFilter: function() {
      this.loadTasks();
    },
  },

  methods: {
    loadTasks: function() {
      if (this.selectedFilter == -1) {
        return;
      }
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/tasks?${this.filter[this.selectedFilter] || ""}`)
        .then((res) => {
          this.tasks = res.data;

           if (this.$route.params.id) {
            this.tasks.find(t => t.id == this.$route.params.id).selected = true
          }

          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },
    openEditor: function() {
      this.$refs.taskCreate.init();
      this.drawer = true;
    },
    closeEditor: function() {
      this.drawer = false;
    },
    onAction: function(action, item) {
      this.$refs.taskCreate.load(item);
      this.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
